import React from 'react'
import { Link } from 'gatsby'

import Layout from "../components/layout"
import Head from '../components/head'
import SEO from '../components/seo'

const AboutPage = () => {
    return (
        <Layout>
            <SEO />
            <Head title="About"/>
            <h1>About Us</h1>
            <hr />
            <br />
            <p className="pages">
                Central Summit is a Malaysian-owned company with extensive hands on experience and earned the reputation of market leader in Stainless Steel Water Tanks industry.
            </p>
            <p className="pages">
                Our mission is to consistently provide our customer with the highest quality workmanship, service and materials available at our industry. We always emphasis on the quality of our product that we provide to our client. We provide constant training to our installers whether in house or on site to raise their awareness especially on safety, construction practice, handling of steel and efficient work programming.
            </p>
            <p className="pages">
                We also offer “Technical After Sales Service” by sending our trained supervisors whom perform regular site visits to ensure the work done by fabricators are accordance to the construction drawings from start to finish for water tanks installation.
            </p>
        </Layout>
    )
}

export default AboutPage